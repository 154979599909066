<template>
  <div style="height: 100vh;">
    <div class="data_center" v-if="!isMobile">
      <div class="data_center_head">
        <div class="data_center_head_content">
          <div
            class="data_center_head_content_companyName"
            @click="toPath('/')"
          >
            <!-- <img src="../../static/dcImg/logo.png" /> -->
            回到首页
          </div>
          <div class="data_center_head_content_companyName_center">
            {{
              provincial.companyName == "中峪农业"
                ? provincial.companyName + "数据中心"
                : provincial.companyName
            }}
          </div>
          <div class="data_center_head_content_time">
            <div class="data_center_head_content_ytd">{{ ytd }}</div>
            <div class="data_center_head_content_getWeek">{{ getWeek() }}</div>
            <div class="data_center_head_content_hms">{{ hms }}</div>
          </div>
        </div>
      </div>
      <div class="data_center_container_bg">
        <div class="left">
          <div class="left_bg"></div>
        </div>
        <div class="center"></div>
        <div class="right">
          <div class="right_bg"></div>
        </div>
      </div>
      <div class="data_center_container">
        <div class="left moveLeft">
          <div class="left_bg"></div>
          <div class="left_total_data">
            <div class="data_head">
              公司数据&nbsp;&nbsp;<span>GONGSISHUJU</span>
            </div>
            <div class="left_total_data_list">
              <div class="left_total_data_list_item">
                <img
                  class="moveTopAnimation2"
                  src="../../static/dcImg/datacenter5.png"
                  width="70px"
                />
                <div
                  class="left_total_data_list_item_desc"
                  @click="toPath('/medicinal')"
                  style="cursor: pointer"
                >
                  <div id="ZZMJ"></div>
                  <div class="left_total_data_list_item_desc_tips">
                    种植面积（亩）
                  </div>
                </div>
              </div>
              <div class="left_total_data_list_item">
                <img
                  class="moveTopAnimation2"
                  src="../../static/dcImg/datacenter4.png"
                  width="70px"
                />
                <div class="left_total_data_list_item_desc">
                  <div id="XSDW"></div>
                  <div class="left_total_data_list_item_desc_tips">
                    下属单位(家)
                  </div>
                </div>
              </div>
              <div class="left_total_data_list_item" style="margin-top: 28px">
                <img
                  class="moveTopAnimation2"
                  src="../../static/dcImg/datacenter3.png"
                  width="70px"
                />
                <div
                  class="left_total_data_list_item_desc"
                  @click="toPath('/medicinal')"
                  style="cursor: pointer"
                >
                  <div id="QDSJ"></div>
                  <div class="left_total_data_list_item_desc_tips">
                    种植密度(株/亩)
                  </div>
                </div>
              </div>
              <div class="left_total_data_list_item" style="margin-top: 28px">
                <img
                  class="moveTopAnimation2"
                  src="../../static/dcImg/datacenter6.png"
                  width="70px"
                />
                <div class="left_total_data_list_item_desc">
                  <div id="GZRY"></div>
                  <div class="left_total_data_list_item_desc_tips">
                    乡村致富(人)
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="left_total_data" style="margin-top: 6%">
            <div class="data_head">
              新闻统计&nbsp;&nbsp;<span>GONGSISHUJU</span>
            </div>
            <div class="left_total_data_list">
              <div class="left_total_data_list_item">
                <div class="left_2_tips">集团重大事件</div>
                <div
                  class="left_2_num"
                  id="DSJ"
                  @click="toPath('/newsList')"
                ></div>
              </div>
              <div class="left_total_data_list_item">
                <div class="left_2_tips">集团新闻资讯</div>
                <div
                  class="left_2_num"
                  id="JTXW"
                  @click="toPath('/newsList')"
                ></div>
              </div>
              <div class="left_total_data_list_item">
                <div class="left_2_bg"></div>
              </div>
              <div class="left_total_data_list_item">
                <div class="left_2_bg"></div>
              </div>
            </div>
          </div>
          <div class="left_total_data" style="margin-top: 6%">
            <div class="data_head">
              药材分布&nbsp;&nbsp;<span>GONGSISHUJU</span>
            </div>
            <div class="left_total_data_list" style="position: relative">
              <!-- <div id="medicinalEchart" style="height: 120px;width: 100%;"></div> -->
              <echart1 style="height: 120px; width: 100%"></echart1>
              <div
                style="
                  cursor: pointer;
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 120px;
                  width: 100%;
                  z-index: 9;
                "
                @click="toPath('/Specific_m')"
              ></div>
            </div>
          </div>
          <div class="left_total_data" style="margin-top: 6%">
            <div class="data_head">
              最新项目&nbsp;&nbsp;<span>GONGSISHUJU</span>
            </div>
            <div class="left_total_data_list">
              <el-scrollbar class="newProject">
                <!-- :key="item.projectId + index" -->
                <div
                  class="left_4_item"
                  v-for="(item, index) in childProjectList"
                  :key="index"
                  @click="toPath('/projectDetail?id=101')"
                >
                  <div class="left_4_item_head">
                    <div>
                      0{{ index + 1 }}&nbsp;&nbsp;{{ item.projectName }}
                    </div>
                    <div style="color: #ffce7c">》</div>
                  </div>
                  <div class="left_4_item_line">
                    <div class="left_4_item_process"></div>
                  </div>
                  <div class="left_4_item_tips">
                    <div>
                      种植进度：{{ item.type ? item.type : "正在进行中" }}
                    </div>
                    <div v-if="item.projectId !== -1">
                      项目时间：{{ item.projectDate.split(",")[0] }}
                      至
                      {{ item.projectDate.split(",")[1] }}
                    </div>
                    <div v-else>暂无</div>
                  </div>
                </div>
              </el-scrollbar>
            </div>
          </div>
        </div>
        <div class="center">
          <echart3
            style="
              height: 610px;
              width: calc(100% - 100px);
              max-width: 830px;
              margin: 0 auto;
            "
            @changeProvincial="changeProvincial"
          ></echart3>
          <div class="left_total_data moveCenter">
            <div class="data_head data_head1">
              <div class="data_head1_select data_head1_title">
                <el-select v-model="medicinal" @change="changeEchart1">
                  <el-option
                    v-for="item in MaterialsNameList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                  <!-- <el-option value="白术二期">白术二期</el-option> -->
                </el-select>
              </div>
              <div class="data_head1_select">
                <el-select v-model="provice" @change="changeEchart2">
                  <el-option
                    v-for="item in MaterialsProvince"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                  <!-- <el-option value="四川省">四川省</el-option> -->
                </el-select>
                <el-select v-model="type" @change="changeEchart3">
                  <el-option
                    v-for="item in MaterialsArea"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                  <!-- <el-option value="川药">川药</el-option> -->
                </el-select>
              </div>
            </div>
            <div class="left_total_data_list">
              <div class="left_total_data_list_head">
                <div class="left_total_data_list_head_left">
                  <div class="left_total_data_list_head_left_bg"></div>
                  热度
                </div>
                <div class="left_total_data_list_head_right">
                  数据来源：中峪数交
                </div>
              </div>
              <echart4
                style="width: 100%"
                ref="echart4"
                :hotlist="this.hotlist"
                :hotTimelist="this.hotTimelist"
              ></echart4>
            </div>
          </div>
        </div>
        <div class="right moveRight">
          <div class="right_bg"></div>
          <div class="left_total_data">
            <div class="data_head">
              药材版块&nbsp;&nbsp;<span>GONGSISHUJU</span>
            </div>
            <div class="left_total_data_list">
              <div class="left_total_data_list_item">
                <img
                  class="moveTopAnimation2"
                  src="../../static/dcImg/datacenter7.png"
                  style="width: 72px !important"
                />
                <div
                  class="left_total_data_list_item_desc"
                  @click="toPath('/introduction?id=2')"
                >
                  <div class="left_total_data_list_item_desc_tips">
                    下属单位(家)
                  </div>
                  <div id="XZDW1"></div>
                </div>
              </div>
              <div class="left_total_data_list_item">
                <img
                  class="moveTopAnimation2"
                  src="../../static/dcImg/datacenter9.png"
                  style="width: 72px !important"
                />
                <div
                  class="left_total_data_list_item_desc"
                  @click="toPath('/medicinal')"
                >
                  <div class="left_total_data_list_item_desc_tips">
                    基地数量(个)
                  </div>
                  <div id="XMZS1"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="left_total_data" style="margin-top: 6%">
            <div class="data_head">
              能源版块&nbsp;&nbsp;<span>GONGSISHUJU</span>
            </div>
            <div class="left_total_data_list">
              <div class="left_total_data_list_item">
                <img
                  class="moveTopAnimation2"
                  src="../../static/dcImg/datacenter7.png"
                  style="width: 72px !important"
                />
                <div
                  class="left_total_data_list_item_desc"
                  @click="toPath('/introduction?id=2')"
                >
                  <div class="left_total_data_list_item_desc_tips">
                    下属单位(家)
                  </div>
                  <div id="XSDW1"></div>
                </div>
              </div>
              <div class="left_total_data_list_item">
                <img
                  class="moveTopAnimation2"
                  src="../../static/dcImg/datacenter9.png"
                  style="width: 72px !important"
                />
                <div
                  class="left_total_data_list_item_desc"
                  @click="toPath('/medicinal')"
                >
                  <div class="left_total_data_list_item_desc_tips">
                    基地数量(个)
                  </div>
                  <div id="XMZS2"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="left_total_data" style="margin-top: 6%">
            <div class="data_head">
              基地类型&nbsp;&nbsp;<span>GONGSISHUJU</span>
            </div>
            <div
              class="left_total_data_list"
              style="position: relative; cursor: pointer"
            >
              <!-- <div id="baseEchart" style="height: 120px;width: 100%;"></div> -->
              <echart2 style="height: 120px; width: 100%"></echart2>
              <div
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 120px;
                  width: 100%;
                  z-index: 9;
                  cursor: pointer;
                "
                @click="toPath('/medicinal')"
              ></div>
            </div>
          </div>
          <div class="left_total_data" style="margin-top: 6%">
            <div class="data_head">
              基地动态&nbsp;&nbsp;<span>GONGSISHUJU</span>
            </div>
            <div
              class="left_total_data_list"
              style="
                position: relative;
                height: 168px;
              "
              v-if="
                childProjectList.length == 2 &&
                  childProjectList[0].projectId == -1
              "
            >
              <!-- dataCenter_bg1.png -->
              <div style="position: relative;">
                <img
                  src="../../static/dcImg/dataCenter_bg1.png"
                  style="width: 100%"
                />
              </div>
              <div class="noImg">基地正在开发中…</div>
            </div>
            <div class="left_total_data_list" v-else>
              <el-scrollbar class="newProject" style="height: 154px">
                <img
                  :src="item"
                  v-for="(item, index) in dataObj.imgList"
                  :key="index"
                  style="margin-bottom: 10px; margin-right: 5%; width: 45%"
                  @click="toPath('/medicinal')"
                />
              </el-scrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="data_center_phone" v-if="isMobile">
      <div class="data_center_phone_back" @click="toPath('/')">
        <i class="el-icon-back"></i>
      </div>
      <div class="data_center_head_phone">
        <div
          class="data_center_head_content_companyName"
          @click="toPath('/')"
          :style="provincial.companyName.length > 10 ? 'font-size: 16px' : ''"
        >
          {{
            provincial.companyName == "中峪农业"
              ? provincial.companyName + "数据中心"
              : provincial.companyName
          }}
        </div>
      </div>
      <div class="data_center_phone_map moveTopAnimation1">
        <img v-show="mapName == '中国'" src="../../static/dcImg/中国.png" />
        <img v-show="mapName == '浙江省'" src="../../static/dcImg/浙江省.png" />
        <img v-show="mapName == '四川省'" src="../../static/dcImg/四川省.png" />
        <img v-show="mapName == '杭州市'" src="../../static/dcImg/杭州市.png" />
        <img v-show="mapName == '湖州市'" src="../../static/dcImg/湖州市.png" />
        <img v-show="mapName == '嘉兴市'" src="../../static/dcImg/嘉兴市.png" />
        <img v-show="mapName == '金华市'" src="../../static/dcImg/金华市.png" />
        <img v-show="mapName == '丽水市'" src="../../static/dcImg/丽水市.png" />
        <img v-show="mapName == '宁波市'" src="../../static/dcImg/宁波市.png" />
        <img v-show="mapName == '衢州市'" src="../../static/dcImg/衢州市.png" />
        <img v-show="mapName == '台州市'" src="../../static/dcImg/台州市.png" />
        <img v-show="mapName == '绍兴市'" src="../../static/dcImg/绍兴市.png" />
        <img v-show="mapName == '温州市'" src="../../static/dcImg/温州市.png" />
        <img v-show="mapName == '舟山市'" src="../../static/dcImg/舟山市.png" />
      </div>
      <div
        class="swiper-container1"
        style="
          margin-bottom: 20px;
          padding: 10px;
          width: calc(100vw - 20px);
          overflow: hidden;
        "
      >
        <div class="swiper-wrapper">
          <div
            class="data_center_phone_list swiper-slide"
            v-for="(item, index) in companyList"
            :key="index"
            style="width: 300px"
          >
            <img
              :src="item.companyImage"
              width="96px"
              height="46px"
              style="margin-left: 7px"
            />
            <div style="margin-left: 10px">
              <div>{{ item.companyName }}</div>
              <div style="display: flex; margin-top: 10px">
                <div
                  class="map_pointer_module_right_click"
                  @click="mapToPath(item)"
                >
                  进入官网
                </div>
                <div
                  class="map_pointer_module_right_click"
                  style="margin-left: 20px"
                  @click="mapToMap(item)"
                >
                  数据中心
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="data_phone_select">
        <el-select v-model="medicinal" @change="changeEchart1">
          <el-option
            v-for="item in MaterialsNameList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
          <!-- <el-option value="白术二期">白术二期</el-option> -->
        </el-select>
        <el-select v-model="provice" @change="changeEchart2">
          <el-option
            v-for="item in MaterialsProvince"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
          <!-- <el-option value="四川省">四川省</el-option> -->
        </el-select>
        <el-select v-model="type" @change="changeEchart3">
          <el-option
            v-for="item in MaterialsArea"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
          <!-- <el-option value="川药">川药</el-option> -->
        </el-select>
      </div>
      <div style="padding: 0 10px;margin: 20px 0;">
        <echart4
          style="width: 100%"
          ref="echart4"
          :hotlist="this.hotlist"
          :hotTimelist="this.hotTimelist"
        ></echart4>
      </div>

      <div class="data_center_container">
        <div class="left moveLeft">
          <div class="left_bg"></div>
          <div class="left_total_data">
            <div class="data_head">
              基地中心&nbsp;&nbsp;<span>GONGSISHUJU</span>
            </div>
            <div class="left_total_data_list">
              <div class="left_total_data_list_item">
                <img
                  class="moveTopAnimation2"
                  src="../../static/dcImg/datacenter5.png"
                  width="60px"
                />
                <div
                  class="left_total_data_list_item_desc"
                  @click="toPath('/medicinal')"
                  style="cursor: pointer"
                >
                  <div id="ZZMJ2"></div>
                  <div class="left_total_data_list_item_desc_tips">
                    种植面积（亩）
                  </div>
                </div>
              </div>
              <div class="left_total_data_list_item">
                <img
                  class="moveTopAnimation2"
                  src="../../static/dcImg/datacenter4.png"
                  width="60px"
                />
                <div class="left_total_data_list_item_desc">
                  <div id="XSDW2"></div>
                  <div class="left_total_data_list_item_desc_tips">
                    下属单位(家)
                  </div>
                </div>
              </div>
              <div class="left_total_data_list_item" style="margin-top: 28px">
                <img
                  class="moveTopAnimation2"
                  src="../../static/dcImg/datacenter3.png"
                  width="60px"
                />
                <div
                  class="left_total_data_list_item_desc"
                  @click="toPath('/medicinal')"
                  style="cursor: pointer"
                >
                  <div id="QDSJ2"></div>
                  <div class="left_total_data_list_item_desc_tips">
                    种植密度(株/亩)
                  </div>
                </div>
              </div>
              <div class="left_total_data_list_item" style="margin-top: 28px">
                <img
                  class="moveTopAnimation2"
                  src="../../static/dcImg/datacenter6.png"
                  width="60px"
                />
                <div class="left_total_data_list_item_desc">
                  <div id="GZRY2"></div>
                  <div class="left_total_data_list_item_desc_tips">
                    乡村致富(人)
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="left_total_data" style="margin-top: 6%">
            <div class="data_head">
              新闻统计&nbsp;&nbsp;<span>GONGSISHUJU</span>
            </div>
            <div class="left_total_data_list">
              <div class="left_total_data_list_item">
                <div class="left_2_tips">集团重大事件</div>
                <div class="left_2_num" id="DSJ" @click="toPath('/newsList')">
                  38
                </div>
              </div>
              <div class="left_total_data_list_item">
                <div class="left_2_tips">集团新闻资讯</div>
                <div
                  class="left_2_num"
                  id="JTXW"
                  @click="toPath('/newsList')"
                ></div>
              </div>
              <div class="left_total_data_list_item">
                <div class="left_2_bg"></div>
              </div>
              <div class="left_total_data_list_item">
                <div class="left_2_bg"></div>
              </div>
            </div>
          </div>
          <div class="left_total_data" style="margin-top: 6%">
            <div class="data_head">
              药材分布&nbsp;&nbsp;<span>GONGSISHUJU</span>
            </div>
            <div class="left_total_data_list" style="position: relative">
              <!-- <div id="medicinalEchart" style="height: 120px;width: 100%;"></div> -->
              <echart1
                style="height: 120px; width: 100%"
                :isMobileE="isMobile"
              ></echart1>
              <div
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 120px;
                  width: 100%;
                  z-index: 9;
                  cursor: pointer;
                "
                @click="toPath('/Specific_m')"
              ></div>
            </div>
          </div>
          <div class="left_total_data" style="margin-top: 6%">
            <div class="data_head">
              最新项目&nbsp;&nbsp;<span>GONGSISHUJU</span>
            </div>
            <div class="left_total_data_list">
              <el-scrollbar class="newProject">
                <!-- :key="item.projectId + index" -->
                <div
                  class="left_4_item"
                  v-for="(item, index) in childProjectList"
                  :key="index"
                  @click="toPath('/projectDetail?id=101')"
                >
                  <div class="left_4_item_head">
                    <div style="font-size: 14px">
                      0{{ index + 1 }}&nbsp;&nbsp;{{ item.projectName }}
                    </div>
                    <div style="color: #ffce7c">》</div>
                  </div>
                  <div class="left_4_item_line">
                    <div class="left_4_item_process"></div>
                  </div>
                  <div class="left_4_item_tips">
                    <div>
                      种植进度：{{ item.type ? item.type : "正在进行中" }}
                    </div>
                    <div v-if="item.projectId !== -1">
                      项目时间：{{ item.projectDate.split(",")[0] }}
                      至
                      {{ item.projectDate.split(",")[1] }}
                    </div>
                    <div v-else>暂无</div>
                  </div>
                </div>
              </el-scrollbar>
            </div>
          </div>
        </div>
        <div class="right moveRight">
          <div class="right_bg"></div>
          <div class="left_total_data">
            <div class="data_head">
              药材版块&nbsp;&nbsp;<span>GONGSISHUJU</span>
            </div>
            <div class="left_total_data_list">
              <div class="left_total_data_list_item">
                <img
                  class="moveTopAnimation2"
                  src="../../static/dcImg/datacenter7.png"
                  style="width: 72px !important; height: 63px"
                />
                <div class="left_total_data_list_item_desc">
                  <div class="left_total_data_list_item_desc_tips">
                    下属单位(家)
                  </div>
                  <div id="XZDW1"></div>
                </div>
              </div>
              <div class="left_total_data_list_item">
                <img
                  class="moveTopAnimation2"
                  src="../../static/dcImg/datacenter9.png"
                  style="width: 72px !important; height: 63px"
                />
                <div class="left_total_data_list_item_desc">
                  <div class="left_total_data_list_item_desc_tips">
                    基地数量(个)
                  </div>
                  <div id="XMZS1"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="left_total_data" style="margin-top: 6%">
            <div class="data_head">
              能源版块&nbsp;&nbsp;<span>GONGSISHUJU</span>
            </div>
            <div class="left_total_data_list">
              <div class="left_total_data_list_item">
                <img
                  class="moveTopAnimation2"
                  src="../../static/dcImg/datacenter7.png"
                  style="width: 72px !important; height: 63px"
                />
                <div class="left_total_data_list_item_desc">
                  <div class="left_total_data_list_item_desc_tips">
                    下属单位(家)
                  </div>
                  <div id="XSDW1"></div>
                </div>
              </div>
              <div class="left_total_data_list_item">
                <img
                  class="moveTopAnimation2"
                  src="../../static/dcImg/datacenter9.png"
                  style="width: 72px !important; height: 63px"
                />
                <div class="left_total_data_list_item_desc">
                  <div class="left_total_data_list_item_desc_tips">
                    基地数量(个)
                  </div>
                  <div id="XMZS2"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="left_total_data" style="margin-top: 6%">
            <div class="data_head">
              基地类型&nbsp;&nbsp;<span>GONGSISHUJU</span>
            </div>
            <div class="left_total_data_list" style="position: relative">
              <!-- <div id="baseEchart" style="height: 120px;width: 100%;"></div> -->
              <echart2 style="height: 120px; width: 100%"></echart2>
              <div
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 120px;
                  width: 100%;
                  z-index: 9;
                  cursor: pointer;
                "
                @click="toPath('/medicinal')"
              ></div>
            </div>
          </div>
          <div class="left_total_data" style="margin-top: 6%">
            <div class="data_head">
              基地动态&nbsp;&nbsp;<span>GONGSISHUJU</span>
            </div>
            <div class="left_total_data_list">
              <div
                style="width:  49%;overflow: hidden;max-height: 98px;overflow: hidden;margin-bottom: 10px;"
                v-for="(item, index) in dataObj.imgList"
                :key="index"
              >
                <img
                  :src="item"
                  style=" width: 100%;max-height: 200px;"
                  @click="toPath('/medicinal')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, Navigation, Pagination, Autoplay, Mousewheel } from "swiper";
import echart1 from "./dataCenter-echart1.vue";
import echart2 from "./dataCenter-echart2.vue";
import echart3 from "./dataCenter-echart3.vue";
import echart4 from "./dataCenter-echart4.vue";
Swiper.use([Navigation, Pagination, Autoplay, Mousewheel]);
import {
  getCompanyList,
  getNewsList,
  getProject,
  getProgress,
  getTrendsDetail,
  getTrends,
  getStatistic,
  materialsList,
  materialsListHot,
} from "@/api/index.js";
export default {
  name: "dataCenter",
  components: { echart1, echart2, echart3, echart4 },
  data() {
    return {
      ytd: "",
      hms: "",
      companyName: "基地中心",
      provice: "",
      type: "",
      medicinal: "",
      isMobile: false,
      mapName: "中国",
      provincial: {},
      pIndex: 1,
      childProjectList: [],
      dataObj: {
        jtxwzx: 0,
        keyan: 0,
        zhongzi: 0,
        zhongmiao: 0,
        zhongzhi: 0,
        imgList: [],
      },
      statisticInfo: {},
      companyList: [],
      MaterialsNameList: [],
      MaterialsArea: [],
      MaterialsProvince: [],
      startOfPreviousWeek: null,
      endOfPreviousWeek: null,
      hotlist: null,
      hotTimelist: null,
    };
  },
  mounted() {
    this.ytd = this.getNowTime("ytd");
    this.hms = this.getNowTime("hms");
    setInterval(() => {
      this.hms = this.getNowTime("hms");
    }, 1000);
    this.isMobile = this.isMobileFun();
    if (!this.isMobile) {
      document.getElementsByClassName("data_center")[0].style =
        "transform: scale(" +
        window.innerWidth / 1920 +
        ");height: calc(" +
        "100vh * " +
        (1 / window.innerWidth) * 1920 +
        ")";
    }
    setTimeout(() => {
      document.getElementsByClassName("left_2_bg")[0].className +=
        " left_2_bg_change";
      document.getElementsByClassName("left_2_bg")[1].className +=
        " left_2_bg_change";
      window.scrollTo(0, 0);
    }, 200);
    this.initData();
  },
  methods: {
    initData() {
      this.getHotlist();
      this.provincial = this.$store.getters.getCompany;
      if (this.provincial.adCode == "330000") {
        this.mapName = "浙江省";
      }
      if (this.provincial.adCode == "510000") {
        this.mapName = "四川省";
      }
      if (
        this.provincial.adCode == "330600" ||
        this.provincial.adCode.substring(0, 4) == "3306"
      ) {
        this.mapName = "绍兴市";
      }
      if (
        this.provincial.adCode == "331000" ||
        this.provincial.adCode.substring(0, 4) == "3310"
      ) {
        this.mapName = "台州市";
      }
      this.getData();
      this.getStatistic();
      this.getCompanyList();
    },
    getCompanyList() {
      this.compamyShow = {};
      const id =
        this.provincial.companyLevel == 4
          ? this.provincial.pid
          : this.provincial.companyId;
      getCompanyList({ current: 1, size: 100, pid: id }).then((res) => {
        this.companyList = res.data.list;
        this.$nextTick(() => {
          this.container3 = new Swiper(".swiper-container1", {
            slidesPerView: "auto",
            spaceBetween: 30,
            observer: true,
            observeParents: true,
            // loop: true,
          });
        });
        // this.initData1()
      });
    },
    getStatistic() {
      getStatistic(this.provincial.companyId).then((res) => {
        const data = res.data;
        this.statisticInfo = {
          yaocai: {
            plantedNumber: data[0].plantedNumber + data[2].plantedNumber,
            plantingDensity: data[0].plantingDensity + data[2].plantingDensity,
            projectPersonnel:
              data[0].projectPersonnel + data[2].projectPersonnel,
            subordinateUnits:
              data[0].subordinateUnits + data[2].subordinateUnits,
          },
          nengyuan: {
            plantedNumber: data[1].plantedNumber + data[2].plantedNumber,
            plantingDensity: data[1].plantingDensity + data[2].plantingDensity,
            projectPersonnel:
              data[1].projectPersonnel + data[2].projectPersonnel,
            subordinateUnits:
              data[1].subordinateUnits + data[2].subordinateUnits,
          },
        };
        this.allCountUp();
      });
    },
    getData() {
      getNewsList({
        companyId: this.provincial.companyId == 3 ? null: this.provincial.companyId,
        websiteId: this.provincial.companyId == 3 ? 3: null,
        current: 1,
        size: 1000,
      }).then((res) => {
        this.dataObj.jtxwzx = res.data.total;
        new this.$countUp("JTXW", res.data.total, {
          suffix: " 个",
        }).start();
      });
      getNewsList({
        companyId: this.provincial.companyId == 3 ? null: this.provincial.companyId,
        websiteId: this.provincial.companyId == 3 ? 3: null,
        recommend: 1,
        current: 1,
        size: 1000,
      }).then((res) => {
        this.dataObj.dsj = res.data.total;
        new this.$countUp("DSJ", res.data.total, {
          suffix: " 个",
        }).start();
      });
      getProject({
        companyId: this.provincial.companyId,
        withChildCompany: 1,
        current: 1,
        size: 4,
      }).then((res) => {
        this.childProjectList = res.data.list;
        let XMZS1 = 0;
        let XMZS2 = 0;
        this.childProjectList.forEach((val) => {
          getProgress({
            projectId: val.projectId,
            current: 1,
            size: 10,
          }).then((res) => {
            val.type = res.data.list[0]
              ? res.data.list[0].progressSchedule
              : "";
          });
          if (val.projectType) {
            const projectType = JSON.parse(val.projectType);
            if (projectType.indexOf("0") > -1) {
              this.dataObj.zhongzi += 1;
            }
            if (projectType.indexOf("1") > -1) {
              this.dataObj.zhongzhi += 1;
            }
            if (projectType.indexOf("2") > -1) {
              this.dataObj.zhongmiao += 1;
            }
            if (projectType.indexOf("3") > -1) {
              this.dataObj.keyan += 1;
            }
            if (projectType.indexOf("4") > -1) {
              this.dataObj.nengyan += 1;
            }
            if (projectType.length > 0 && projectType.indexOf(4) == -1) {
              XMZS1 += 1;
            }
            if (projectType.indexOf(4) > -1) {
              XMZS2 += 1;
            }
          }
        });
        if (this.childProjectList.length == 0) {
          this.childProjectList = [
            {
              projectId: -1,
              projectName: "基地正在开发中···",
              type: "暂无",
            },
            {
              projectId: -1,
              projectName: "基地正在开发中···",
              type: "暂无",
            },
          ];
        } else {
          getTrends({
            projectId: 101,
            current: 1,
            size: 10,
          }).then((res) => {
            getTrendsDetail(res.data.list[0].trendsId).then((res1) => {
              this.dataObj.imgList = res1.data.trendsContent.split(",");
            });
          });
          new this.$countUp("XMZS1", XMZS1).start();
          new this.$countUp("XMZS2", XMZS2).start();

          this.$nextTick(() => {
            setTimeout(() => {
              const domList = document.querySelectorAll(".left_4_item_process");
              domList[0] &&
                (domList[0].style.width =
                  this.childProjectList[0].type == "种植期"
                    ? "10%"
                    : this.childProjectList[0].type == "维护期"
                    ? "33%"
                    : this.childProjectList[0].type == "丰收期"
                    ? "66%"
                    : "0%");
              domList[1] &&
                (domList[1].style.width =
                  this.childProjectList[1].type == "种植期"
                    ? "10%"
                    : this.childProjectList[1].type == "维护期"
                    ? "33%"
                    : this.childProjectList[1].type == "丰收期"
                    ? "66%"
                    : "0%");
              domList[2] &&
                (domList[2].style.width =
                  this.childProjectList[2].type == "种植期"
                    ? "10%"
                    : this.childProjectList[2].type == "维护期"
                    ? "33%"
                    : this.childProjectList[2].type == "丰收期"
                    ? "66%"
                    : "0%");
            }, 500);
          });
        }
      });
    },
    changeProvincial(provincial) {
      this.provincial = provincial;
    },
    toNextMap() {},
    async changeEchart1(e, id) {
      this.triggerChildEvent();
      console.log(e, id, "id");
      let data;
      let x;
      if (!id) {
        console.log(id);
        // this.MaterialsNameList[e];
        x = this.getPreviousSevenDays();
        console.log(x[0], x[6], this.MaterialsNameList[e].id);
        data = {
          medicinalMaterialsId: this.MaterialsNameList[e].id,
          trendingStartData: x[6],
          trendingEndData: x[0],
        };
      } else {
        console.log(id, "id");

        //  this.MaterialsNameList[e];
        x = this.getPreviousSevenDays();
        console.log(x[0], x[6]);
        data = {
          medicinalMaterialsId: id,
          trendingStartData: x[6],
          trendingEndData: x[0],
        };
      }
      await materialsListHot(data).then((res) => {
        console.log(res.data.list);
        this.hotlist = res.data.list;
        this.hotTimelist = x;
      });
      if (this.$refs.echart4) {
        this.$refs.echart4.initChart();
      }
    },
    changeEchart2(e) {
      this.type = null;
      console.log(e);
      console.log(e, this.MaterialsProvince[e]);
      const data = {
        medicinalMaterialsProvince: this.MaterialsProvince[e].label,
      };
      this.getHotlist(data);
    },
    changeEchart3(e) {
      this.provice = null;
      console.log(e, this.MaterialsArea[e]);
      const data = {
        medicinalMaterialsArea: this.MaterialsArea[e].label,
      };
      this.getHotlist(data);
    },
    isMobileFun() {
      const userAgent = navigator.userAgent;
      const mobileKeywords = /Mobile|Android|iPhone|iPad|iPod|Windows Phone/i;
      return mobileKeywords.test(userAgent);
    },
    toPath(path) {
      const company = this.$store.getters.getCompany;
      if (path == "/") {
        if (company.companyLevel == 1) {
          if (this.$route.path == "/") {
            window.location.reload();
          } else {
            this.$router.push({ path: `/` });
          }
        }
        if (company.companyLevel == 2) {
          if (this.$route.path == "/ProvincialCompany") {
            window.location.reload();
          } else {
            this.$router.push({ path: `/ProvincialCompany` });
          }
        }
        if (company.companyLevel == 3) {
          if (this.$route.path == "/cityCompany") {
            window.location.reload();
          } else {
            this.$router.push({ path: `/cityCompany` });
          }
        }
        if (company.companyLevel == 4) {
          if (this.$route.path == "/areaCompany") {
            window.location.reload();
          } else {
            this.$router.push({ path: `/areaCompany` });
          }
        }
      } else {
        this.$router.push(path);
      }
    },
    mapToPath(item) {
      const company = item;
      this.$store.commit("setCompany", company);
      if (company.companyLevel == 1) {
        this.$router.push({ path: `/` });
      }
      if (company.companyLevel == 2) {
        this.$router.push({ path: `/ProvincialCompany` });
      }
      if (company.companyLevel == 3) {
        this.$router.push({ path: `/cityCompany` });
      }
      if (company.companyLevel == 4) {
        this.$router.push({ path: `/areaCompany` });
      }
    },
    mapToMap(item) {
      const adCode = item.adCode;
      this.$store.commit("setCompany", item);
      if (adCode == "330000") {
        this.mapName = "浙江省";
      }
      if (adCode == "510000") {
        this.mapName = "四川省";
      }
      if (adCode == "330600" || adCode.substring(0, 4) == "3306") {
        this.mapName = "绍兴市";
      }
      if (adCode == "331000" || adCode.substring(0, 4) == "3310") {
        this.mapName = "台州市";
      }
      this.provincial = item;
      if (item.companyLevel == 4) {
        this.companyList = [item];
        return;
      }
      this.getCompanyList();
    },
    allCountUp() {
      new this.$countUp(
        "ZZMJ",
        this.statisticInfo.yaocai.plantedNumber +
          this.statisticInfo.nengyuan.plantedNumber
      ).start();
      new this.$countUp(
        "XSDW",
        this.statisticInfo.yaocai.subordinateUnits +
          this.statisticInfo.nengyuan.subordinateUnits
      ).start();
      new this.$countUp(
        "GZRY",
        this.statisticInfo.yaocai.projectPersonnel +
          this.statisticInfo.nengyuan.projectPersonnel
      ).start();
      new this.$countUp(
        "QDSJ",
        this.statisticInfo.yaocai.plantingDensity +
          this.statisticInfo.nengyuan.plantingDensity
      ).start();
      new this.$countUp(
        "XZDW1",
        this.statisticInfo.yaocai.subordinateUnits
      ).start();
      new this.$countUp(
        "XSDW1",
        this.statisticInfo.nengyuan.subordinateUnits
      ).start();
      new this.$countUp(
        "ZZMJ2",
        this.statisticInfo.yaocai.plantedNumber +
          this.statisticInfo.nengyuan.plantedNumber
      ).start();
      new this.$countUp(
        "XSDW2",
        this.statisticInfo.yaocai.subordinateUnits +
          this.statisticInfo.nengyuan.subordinateUnits
      ).start();
      new this.$countUp(
        "QDSJ2",
        this.statisticInfo.yaocai.plantingDensity +
          this.statisticInfo.nengyuan.plantingDensity
      ).start();
      new this.$countUp(
        "GZRY2",
        this.statisticInfo.yaocai.projectPersonnel +
          this.statisticInfo.nengyuan.projectPersonnel
      ).start();
    },
    getNowTime(isAll) {
      let now = new Date();
      let year = now.getFullYear();
      let month = now.getMonth() + 1;
      let today = now.getDate();
      let hour = now.getHours();
      let minute = now.getMinutes();
      let second = now.getSeconds();
      let nowTime = "";
      //返回年月日时分秒
      if (isAll == "hms") {
        nowTime =
          this.fillZero(hour) +
          ":" +
          this.fillZero(minute) +
          ":" +
          this.fillZero(second);
      } else if (isAll == "ytd") {
        //返回年月日
        nowTime =
          year + "." + this.fillZero(month) + "." + this.fillZero(today);
      }
      return nowTime;
    },
    fillZero(str) {
      var realNum;
      if (str < 10) {
        realNum = "0" + str;
      } else {
        realNum = str;
      }
      return realNum;
    },
    getWeek() {
      let weeks = {
        0: "星期日",
        1: "星期一",
        2: "星期二",
        3: "星期三",
        4: "星期四",
        5: "星期五",
        6: "星期六",
      };
      let date = new Date();
      let weekIndex = date.getDay();
      date = weeks[weekIndex];
      return date;
    },
    async getHotlist(data) {
      const x = this.getPreviousSevenDays();
      console.log(
        this.startOfPreviousWeek,
        data,
        x[0],
        x[6],
        this.endOfPreviousWeek
      );
      this.MaterialsNameList = [];
      // this.MaterialsArea = [];
      // this.MaterialsProvince = [];
      if (!data) {
        await materialsList().then(async (res) => {
          res.data.list.forEach((e, index) => {
            this.MaterialsNameList.push({
              value: index,
              id: e.id,
              label: e.medicinalMaterialsName,
            });
            this.MaterialsArea.push({
              label: e.medicinalMaterialsArea,
            });
            this.MaterialsProvince.push({
              label: e.medicinalMaterialsProvince,
            });
          });
          let waitList = [];
          this.MaterialsProvince.forEach((x) => {
            waitList.push(x.label);
          });
          let x = Array(...new Set(waitList));
          this.MaterialsProvince = [];
          x.forEach((E, index) => {
            this.MaterialsProvince.push({
              value: index,
              label: E,
            });
          });

          let waitListArea = [];
          this.MaterialsArea.forEach((x) => {
            waitListArea.push(x.label);
          });
          let xArea = Array(...new Set(waitListArea));
          this.MaterialsArea = [];
          xArea.forEach((E, index) => {
            this.MaterialsArea.push({
              value: index,
              label: E,
            });
          });
          console.log(
            this.MaterialsProvince[0],
            this.MaterialsArea[0],
            this.MaterialsNameList[0]
          );
          // this.type = this.MaterialsArea[0].label;
          // this.provice = this.MaterialsProvince[0].label;
          this.medicinal = this.MaterialsNameList[0].value;
          console.log(this.medicinal, this.MaterialsNameList);
          this.changeEchart1(
            this.MaterialsNameList[0],
            this.MaterialsNameList[0].id
          );
        });
      } else {
        materialsList(data).then((res) => {
          res.data.list.forEach((e, index) => {
            this.MaterialsNameList.push({
              id: e.id,
              value: index,
              label: e.medicinalMaterialsName,
            });
            // this.MaterialsArea.push({
            //   label: e.medicinalMaterialsArea,
            // });
            // this.MaterialsProvince.push({
            //   label: e.medicinalMaterialsProvince,
            // });
          });
          // let waitList = [];
          // this.MaterialsProvince.forEach((x) => {
          //   waitList.push(x.label);
          // });
          // let x = Array(...new Set(waitList));
          // this.MaterialsProvince = [];
          // x.forEach((E, index) => {
          //   this.MaterialsProvince.push({
          //     value: index,
          //     label: E,
          //   });
          // });

          // let waitListArea = [];
          // this.MaterialsArea.forEach((x) => {
          //   waitListArea.push(x.label);
          // });
          // let xArea = Array(...new Set(waitListArea));
          // this.MaterialsArea = [];
          // xArea.forEach((E, index) => {
          //   this.MaterialsArea.push({
          //     value: index,
          //     label: E,
          //   });
          // });
          // console.log(
          //   this.MaterialsProvince[0],
          //   this.MaterialsArea[0],
          //   this.MaterialsNameList[0]
          // );
          // this.type = this.MaterialsArea[0].label;
          // this.provice = this.MaterialsProvince[0].label;
          this.medicinal = this.MaterialsNameList[0].value;
          console.log(this.medicinal);
          this.changeEchart1(
            this.MaterialsNameList[0],
            this.MaterialsNameList[0].id
          );
        });
      }
    },
    triggerChildEvent() {
      if (this.$refs.echart4) {
        this.$refs.echart4.childMethod();
      }
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从0开始，需要加1
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}/${month}/${day}`;
    },

    getPreviousSevenDays() {
      const today = new Date();
      const dates = [];

      for (let i = 1; i <= 7; i++) {
        const date = new Date(today);
        date.setDate(today.getDate() - i);
        dates.push(this.formatDate(date));
      }

      return dates;
    },
  },
  beforeDestroy() {},
};
</script>

<style scoped lang="scss">
$headHeight: 75px;
$headContentHeight: calc(168 / 4288 * 100vw / 2 + 20px);

.data_center {
  width: 1920px;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  background-image: url("../../static/dcImg/datacenter1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 120%;
  animation: scaleBg 1s linear 1;
  transform-origin: 0 0;

  @keyframes scaleBg {
    0% {
      background-size: 120%;
    }

    100% {
      background-size: 100%;
    }
  }

  .data_center_head {
    background-image: url("../../static/dcImg/datacenter2.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    width: 100%;
    position: relative;
    height: $headHeight;

    .data_center_head_content {
      display: flex;
      padding: 0 20px;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: $headContentHeight;

      .data_center_head_content_companyName {
        vertical-align: middle;
        font-family: 黑体;
        font-size: 22px;
        color: #fff7d8;
        letter-spacing: 1px;
        text-shadow: 0px 0px 10px #ff9900, 0px 0px 4px #ffc700;
        width: 400px;
        cursor: pointer;
      }

      .data_center_head_content_companyName_center {
        font-family: 黑体;
        font-size: 28px;
        font-weight: bold;
        color: #fff7d8;
        letter-spacing: 1px;
        text-shadow: 0px 0px 10px #ff9900, 0px 0px 4px #ffc700;
        margin-top: 10px;
      }

      img {
        height: 26px;
        vertical-align: sub;
      }

      .data_center_head_content_time {
        color: #ffdb56;
        display: flex;
        font-size: 16px;
        line-height: 80px;
        letter-spacing: 1px;
        width: 400px;
        justify-content: flex-end;

        .data_center_head_content_getWeek {
          margin: 0 20px;
        }

        .data_center_head_content_hms {
          color: #fff7d8;
          text-shadow: 0px 0px 10px #ff9900, 0px 0px 4px #ffc700;
        }
      }
    }
  }

  .data_center_container_bg {
    position: absolute;
    top: calc(168 / 4288 * 100vw);
    left: 0;
    transform-origin: 0 0;
    display: flex;

    .left {
      width: 485px;
      overflow: hidden;
      position: relative;

      .left_bg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(
          to top,
          rgba(255, 188, 0, 0),
          rgba(80, 58, 34, 0.3)
        );
        filter: blur(50px);
        z-index: -1;
      }
    }

    .center {
      width: 950px;
      height: 809px;
    }

    .right {
      width: 485px;
      overflow: hidden;
      position: relative;

      .right_bg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(
          to top,
          rgba(255, 188, 0, 0),
          rgba(80, 58, 34, 0.3)
        );
        filter: blur(50px);
        z-index: -1;
      }
    }
  }

  .data_center_container {
    width: 1850px;
    padding: 0 35px 35px 35px;
    display: flex;
    transform-origin: 0 0;

    .data_head {
      font-weight: bold;
      font-size: 20px;
      color: #f4e1c9;
      line-height: 51px;
      text-shadow: 0px 0px 7px rgba(255, 150, 21, 0.55);
      background-image: url("../../static/dcImg/datacenter8.png");
      background-repeat: no-repeat;
      background-size: contain;
      height: 51px;
      padding: 0 40px;

      span {
        font-weight: 500;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.22);
        line-height: 51px;
      }
    }

    .data_head1 {
      display: flex;
      background-image: url("../../static/dcImg/datacenter12.png");
      justify-content: space-between;

      .data_head1_select {
        line-height: 44px;
      }
    }

    .left {
      width: 450px;
      overflow: hidden;
      position: relative;

      .left_total_data {
        .left_total_data_list {
          margin-top: 24px;
          display: flex;
          flex-wrap: wrap;

          .left_total_data_list_item {
            width: 50%;
            display: flex;
            align-items: center;

            .left_total_data_list_item_desc {
              // cursor: pointer;
              font-size: 24px;
              color: #ede9da;
              line-height: 30px;
              padding-left: 21px;

              .left_total_data_list_item_desc_tips {
                cursor: auto;

                font-size: 16px;
                color: #ede9da;
                line-height: 22px;
              }
            }

            .left_2_tips {
              font-size: 16px;
              color: #f4e1c9;
              line-height: 31px;
            }

            .left_2_num {
              margin-left: 25px;
              font-size: 22px;
              color: #ffffff;
              line-height: 31px;
            }

            .left_2_bg {
              transition: all 1s;
              margin-top: 20px;
              width: 0;
              height: 8px;
              background: linear-gradient(
                259deg,
                #f2911b 0%,
                rgba(242, 169, 27, 0) 100%
              );
            }

            .left_2_bg_change {
              width: 178px;
            }
          }
        }

        .newProject {
          height: 124px;

          .left_4_item {
            cursor: pointer;
            margin-top: 8px;
            width: 388px;
            height: 88px;
            padding: 16px;
            background: linear-gradient(
              90deg,
              rgba(106, 69, 19, 0.49) 0%,
              rgba(112, 82, 34, 0.19) 88%,
              rgba(113, 83, 36, 0.15) 100%
            );

            .left_4_item_head {
              color: #fff0d0;
              display: flex;
              justify-content: space-between;
            }

            .left_4_item_line {
              width: 100%;
              height: 6px;
              background: rgba(255, 255, 255, 0.29);
              border-radius: 7px;
              margin-top: 26px;

              .left_4_item_process {
                transition: all 1s;
                width: 0;
                height: 6px;
                background: linear-gradient(90deg, #b05315 0%, #ffea00 100%);
                border-radius: 7px;
              }

              .left_4_item_process_change {
                width: 50%;
              }
            }

            .left_4_item_tips {
              display: flex;
              font-size: 12px;
              color: #d4ccbf;
              line-height: 17px;
              margin-top: 15px;
              justify-content: space-between;
            }
          }

          .left_4_item:first-child {
            margin-top: 0;
          }
        }
      }
    }

    .center {
      width: 950px;
      height: 809px;

      .left_total_data_list_head {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #f1eadf;
        line-height: 20px;
        padding: 20px 40px 0 10px;

        .left_total_data_list_head_left_bg {
          width: 12px;
          height: 6px;
          background: #fa7707;
          display: inline-block;
          margin-right: 5px;
          vertical-align: middle;
        }
      }
    }

    .right {
      width: 450px;
      overflow: hidden;
      position: relative;

      .left_total_data {
        .left_total_data_list {
          // cursor: pointer;
          margin-top: 24px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .left_total_data_list_item {
            width: 50%;
            display: flex;
            align-items: center;

            .left_total_data_list_item_desc {
              // cursor: pointer;
              font-size: 34px;
              color: #ede9da;
              line-height: 57px;
              padding-left: 12px;

              .left_total_data_list_item_desc_tips {
                cursor: auto;

                font-size: 16px;
                color: #ede9da;
                line-height: 22px;
              }
            }
          }

          img {
            width: 49%;
            height: fit-content;
          }

          img:nth-child(n + 3) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}

.el-select {
  margin-right: 50px;

  ::v-deep .el-input__inner {
    width: 120px;
    height: 27px;
    background: rgba(255, 255, 255, 0.11);
    font-size: 12px;
    color: #f4e1c9;
    line-height: 27px;
    border-radius: 0;
    border: none;
  }
}
.data_phone_select {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  .el-select {
    margin-right: 10px;
    ::v-deep .el-input__icon {
      line-height: 22px;
    }
    ::v-deep .el-input__inner{
      width: 105px;
    }
  }
}

.data_head1_title {
  .el-select {
    ::v-deep .el-input__inner {
      width: 130px;
      border: none;
      background-color: transparent;
      font-size: 20px;
      color: #f4e1c9;
      text-shadow: 0px 0px 7px rgba(255, 150, 21, 0.55);
      line-height: 27px;
    }
  }
}

.data_center_phone {
  position: relative;
  width: 100vw;
  background-image: url("../../static/dcImg/dataCenter_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .data_center_phone_back {
    position: absolute;
    top: 10px;
    left: 5px;
    color: #fff;
    font-size: 20px;
  }

  .data_center_phone_map {
    margin-top: 10px;
    padding: 0 10px;
    text-align: center;
    position: relative;

    img {
      max-height: 280px;
      max-width: 100%;
    }
  }
  .data_center_phone_list {
    padding: 10px 0;
    background: linear-gradient(180deg, #5f4e11 0%, #3f2f00 100%);
    border-radius: 0px 11px 11px 0px;
    display: flex;
    font-size: 12px;
    color: #ede9da;
    border-left: 2px solid #fdbd00;
    .map_pointer_module_right_click {
      color: #fdbd00;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .data_center_head_phone {
    background-image: url("../../static/dcImg/datacenter2.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    width: 100%;
    position: relative;
    height: 50px;

    .data_center_head_content_companyName {
      vertical-align: middle;
      font-family: 黑体;
      font-size: 20px;
      color: #fff7d8;
      letter-spacing: 1px;
      text-shadow: 0px 0px 10px #ff9900, 0px 0px 4px #ffc700;
      width: 400px;
      cursor: pointer;
      text-align: center;
      line-height: 51px;
    }

    .data_center_head_content_phone {
      display: flex;
      padding: 0 20px;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 50px;

      img {
        height: 52px;
        cursor: pointer;
        vertical-align: middle;
      }

      .data_center_head_content_time_phone {
        color: #ffdb56;
        display: flex;
        font-size: 12px;
        letter-spacing: 1px;

        .data_center_head_content_getWeek_phone {
          margin: 0 20px;
        }

        .data_center_head_content_hms_phone {
          margin-left: 5px;
          color: #fff7d8;
          text-shadow: 0px 0px 10px #ff9900, 0px 0px 4px #ffc700;
        }
      }
    }
  }

  .data_center_container {
    width: calc(100% - 20px);
    padding: 10px;

    .data_head {
      font-weight: bold;
      font-size: 20px;
      color: #f4e1c9;
      line-height: 51px;
      text-shadow: 0px 0px 7px rgba(255, 150, 21, 0.55);
      background-image: url("../../static/dcImg/datacenter8.png");
      background-repeat: no-repeat;
      background-size: cover;
      height: 51px;
      padding: 0 40px;

      span {
        font-weight: 500;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.22);
        line-height: 51px;
      }
    }

    .data_head1 {
      display: flex;
      background-image: url("../../static/dcImg/datacenter12.png");
      justify-content: space-between;

      .data_head1_select {
        line-height: 44px;
      }
    }

    .left {
      width: 100%;
      position: relative;

      .left_total_data {
        .left_total_data_list {
          width: 100%;
          margin-top: 24px;
          display: flex;
          flex-wrap: wrap;

          .left_total_data_list_item {
            width: 50%;
            display: flex;
            align-items: center;

            .left_total_data_list_item_desc {
              cursor: pointer;
              font-size: 22px;
              color: #ede9da;
              line-height: 30px;
              padding-left: 21px;

              .left_total_data_list_item_desc_tips {
                font-size: 12px;
                color: #ede9da;
                line-height: 22px;
              }
            }

            .left_2_tips {
              font-size: 12px;
              color: #f4e1c9;
              line-height: 31px;
            }

            .left_2_num {
              margin-left: 25px;
              font-size: 22px;
              color: #ffffff;
              line-height: 31px;
            }

            .left_2_bg {
              transition: all 1s;
              margin-top: 20px;
              width: 0;
              height: 8px;
              background: linear-gradient(
                259deg,
                #f2911b 0%,
                rgba(242, 169, 27, 0) 100%
              );
            }

            .left_2_bg_change {
              width: 80%;
            }
          }
        }

        .newProject {
          width: 100%;

          .left_4_item {
            cursor: pointer;
            margin-top: 8px;
            width: calc(100% - 32px);
            height: 88px;
            padding: 16px;
            background: linear-gradient(
              90deg,
              rgba(106, 69, 19, 0.49) 0%,
              rgba(112, 82, 34, 0.19) 88%,
              rgba(113, 83, 36, 0.15) 100%
            );

            .left_4_item_head {
              color: #fff0d0;
              display: flex;
              justify-content: space-between;
            }

            .left_4_item_line {
              width: 100%;
              height: 6px;
              background: rgba(255, 255, 255, 0.29);
              border-radius: 7px;
              margin-top: 26px;

              .left_4_item_process {
                transition: all 1s;
                width: 0;
                height: 6px;
                background: linear-gradient(90deg, #b05315 0%, #ffea00 100%);
                border-radius: 7px;
              }

              .left_4_item_process_change {
                width: 50%;
              }
            }

            .left_4_item_tips {
              display: flex;
              font-size: 12px;
              color: #d4ccbf;
              line-height: 17px;
              margin-top: 15px;
              justify-content: space-between;
            }
          }

          .left_4_item:first-child {
            margin-top: 0;
          }
        }
      }
    }

    .right {
      margin-top: 6%;
      width: 100%;
      position: relative;

      .left_total_data {
        .left_total_data_list {
          width: 100%;
          margin-top: 24px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .left_total_data_list_item {
            width: 50%;
            display: flex;
            align-items: center;

            .left_total_data_list_item_desc {
              // cursor: pointer;
              font-size: 22px;
              color: #ede9da;
              line-height: 57px;
              padding-left: 12px;

              .left_total_data_list_item_desc_tips {
                font-size: 12px;
                color: #ede9da;
                line-height: 22px;
              }
            }
          }

          img {
            width: 49%;
            height: fit-content;
          }

          img:nth-child(n + 3) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.p_map_pointer_dataCenter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;

  .p_map_pointer_zhejiang,
  .p_map_pointer_shaoxin,
  .p_map_pointer_keqiao {
    position: absolute;
    right: 105px;
    bottom: 85px;

    .p_map_pointer_module_name {
      position: relative;
    }

    .p_map_pointer_module::after {
      content: " ";
      position: absolute;
      top: 32px;
      right: -20px;
      width: 20px;
      height: 2px;
      background-color: rgba(253, 189, 0, 0.7);
    }

    .p_map_pointer_module {
      display: flex;
      align-items: center;
      position: absolute;
      left: -210px;
      top: -24px;
      color: #fff;
      padding-left: 20px;
      padding-top: 10px;

      .p_map_pointer_module_left {
        width: 2px;
        height: 32px;
        background: #fdbd00;
      }

      .p_map_pointer_module_center {
        width: 30px;
        height: 48px;
        background: rgba(12, 10, 0, 0.8);
        text-align: center;
      }

      .p_map_pointer_module_right {
        width: 134px;
        height: 48px;
        background: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        line-height: 23px;
        padding-left: 10px;

        .p_map_pointer_module_right_click {
          color: #fdbd00;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .p_map_pointer_shaoxin {
    right: 160px;
    bottom: 180px;
  }

  .p_map_pointer_keqiao {
    right: 160px;
    bottom: 150px;
  }
}

.noImg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #ffe3a6;
}
</style>
