<template>
  <div class="echarts">
    <div id="lineEchart" style="height: 100%; width: 100%"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-gl";
export default {
  name: "dotMap",
  props: {
    hotlist: {
      typeof: Array,
      required: false,
    },
    hotTimelist: {
      typeof: Array,
      required: false,
    },
  },
  data() {
    return {
      myChart: null,
      echart4Change: false,
      echart4Data: [],
      hotTimelistSon: [],
    };
  },
  mounted() {
    // let list = [];
    this.hotTimelistSon = [];
    this.echart4Data = [];
    this.myChart = echarts.init(document.getElementById("lineEchart"));
    setTimeout(() => {
      console.log(this.myChart);

      this.getOnlist();
      //   this.hotlist.forEach((es) => {
      //     this.hotTimelist.forEach((e) => {
      //       const date = es.medicinalMaterialsDate.replace(/\-/g, "/");
      //   console.log(date, date != e);
      //       if (date != e) {
      //         list.push({ e, medicinalMaterialsTrending: 0 });
      //       } else {
      //         list.push({
      //           e,
      //           medicinalMaterialsTrending: es.medicinalMaterialsTrending,
      //         });
      //       }
      //     });
      //   });
      //   console.log(list);
      //   let uniqueData = list.reduce((acc, curr) => {
      //     const existing = acc.find((item) => item.e === curr.e);
      //     if (!existing) {
      //       acc.push(curr);
      //     } else if (curr.medicinalMaterialsTrending != 0) {
      //       existing.medicinalMaterialsTrending = curr.medicinalMaterialsTrending;
      //     }
      //     return acc;
      //   }, []);
      //   uniqueData = uniqueData.sort((a, b) => new Date(a.e) - new Date(b.e));
      //   console.log(uniqueData);
      //   uniqueData.forEach((e) => {
      //     this.echart4Data.push(e.medicinalMaterialsTrending);
      //   });
      //   uniqueData.forEach((e) => {
      //     this.hotTimelistSon.push(e.e);
      //   });
      //   console.log(
      //     this.hotlist,
      //     this.echart4Data,
      //     this.hotTimelist,
      //     list,
      //     uniqueData,
      //     "123"
      //   );
      this.initChart();
    }, 500);
  },
  methods: {
    getOnlist() {
      //   this.myChart.clear();
      this.hotTimelistSon = [];
      let list = [];
      this.echart4Data = [];
      console.log(this.hotlist);
      if (this.hotlist) {
        this.hotlist.forEach((es) => {
          this.hotTimelist.forEach((e) => {
            const date = es.medicinalMaterialsDate.replace(/\-/g, "/");
            //   console.log(date, date != e);
            if (date != e) {
              list.push({ e, medicinalMaterialsTrending: 0 });
            } else {
              list.push({
                e,
                medicinalMaterialsTrending: es.medicinalMaterialsTrending,
              });
            }
          });
        });
        //   console.log(list);
        let uniqueData = list.reduce((acc, curr) => {
          const existing = acc.find((item) => item.e === curr.e);
          if (!existing) {
            acc.push(curr);
          } else if (curr.medicinalMaterialsTrending != 0) {
            existing.medicinalMaterialsTrending =
              curr.medicinalMaterialsTrending;
          }
          return acc;
        }, []);
        uniqueData = uniqueData.sort((a, b) => new Date(a.e) - new Date(b.e));
        //   console.log(uniqueData);
        uniqueData.forEach((e) => {
          this.echart4Data.push(e.medicinalMaterialsTrending);
        });

        uniqueData.forEach((e) => {
          this.hotTimelistSon.push(e.e);
        });
        console.log(this.echart4Data, this.hotTimelistSon, "123");
      }
    },
    async initChart() {
      console.log(9954);
      if (this.hotlist) {
        await this.getOnlist();
      }
      //   setTimeout(() => {

      //   }, 500);

      //   if (this.echart4Change) {
      // this.echart4Data = [100, 75, 50, 25];
      //   } else {
      // this.echart4Data = [25, 50, 75, 100];
      //   }
      this.echart4Change = !this.echart4Change;
      const lineY = [];
      const data = {
        name: "热度",
        type: "line",
        color: "rgba(255, 209, 92)",
        smooth: true,
        areaStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(
              0,
              0,
              0,
              1,
              [
                {
                  offset: 0,
                  color: "rgba(255, 209, 92, 0.3)",
                },
                {
                  offset: 0.8,
                  color: "rgba(255, 209, 92, 0)",
                },
              ],
              false
            ),
            shadowColor: "rgba(0, 0, 0, 0.1)",
            shadowBlur: 10,
          },
        },
        symbol: "circle",
        symbolSize: 5,
        data: this.echart4Data,
      };
      lineY.push(data);
      const userAgent = navigator.userAgent;
      const phone = [
        "Mobile",
        "Android",
        "iPhone",
        "iPad",
        "Windows Phone",
      ].some((keyword) => userAgent.includes(keyword));
      let grid = {
        top: "14%",
        left: "4%",
        right: "7%",
        bottom: "19%",
      };
      if (phone) {
        grid = {
          top: "0%",
          left: "15%",
          right: "10%",
          bottom: "18%",
        };
      }
      const option = {
        tooltip: {
          trigger: "axis",
        },
        grid: grid,
        xAxis: {
          type: "category",
          boundaryGap: false,
          //   data: ["2024-07-01", "2024-07-15", "2024-07-30", "2024-08-01"],
          data: this.hotTimelistSon,
          axisLabel: {
            textStyle: {
              color: "#FFFFFF",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value}",
            textStyle: {
              color: "#FFFFFF",
            },
          },
          splitLine: {
            lineStyle: {
              color: "#4D4635",
            },
          },
          axisLine: {
            lineStyle: {
              color: "#FFFFFF",
            },
          },
        },
        series: lineY,
      };
      this.myChart.setOption(option);
    },
    async childMethod() {
      this.myChart.clear();
      await this.initChart();
    },
  },
};
</script>
<style lang="scss" scoped>
.echarts {
  width: 100%;
  height: 101px;
  position: relative;

  .echarts_bg {
    position: absolute;
    top: 0px;
    left: 6px;
    z-index: -1;
    width: 250px;
  }
}
</style>
